@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';

html,
body {
  background-color: #f1f1f1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Inter', sans-serif;
}

.primary-color {
  color: #6d54fc;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }

  a {
    font-family: 'Artegra-Semibold';
  }
}

@media (min-width: 992px) {
  .product-card {
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }

  nz-card {
    border-radius: 0px !important;
    border: none !important;
  }
}

.product-card {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.b-shadow {
  box-shadow: 0 4px 2px 4px rgba(172, 186, 202, 0.1);
  padding-bottom: 12px;
}

.search-icon {
  color: #c9c9c9 !important;

  svg {
    color: #c9c9c9 !important;
  }

  .anticon > * {
    color: #c9c9c9 !important;
  }
}

.w-93 {
  width: 93%;
}

.ant-btn {
  height: 48px !important;
}

.ant-slider-handle {
  width: 22px !important;
  height: 22px !important;
  margin-top: -8px !important;
}

.ant-slider-track {
  background-color: #6d54fc !important;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #c9c9c9;
}

.ant-slider-mark-text,
ant-slider-mark-active .ng-star-inserted {
  width: max-content;
  margin-top: 15px;

  &:first-child {
    margin-left: 10px;
  }

  &:last-child {
    margin-left: -30px;
  }
}

.w-87 {
  width: 87%;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  margin-left: 10px;
  color: #333333 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.back-icon {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;
}

.sellerName {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
}

.shopping-cart {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.clean {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 3px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.02em;
  color: #666666;
}

.filterApplied {
  color: #6d54fc !important;
}

.filter {
  margin-left: 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #666666;
}

.filter-img {
  margin-right: 4px;
  margin-left: 5px;
  color: #666666;

  svg {
    height: 12px;
    width: 12px;
  }
}

.filter-txt {
  padding-bottom: 13px;
  font-size: 14px;
  color: #979797;
}

.n-mt {
  margin-top: -7px !important;
}

.ant-tag-green {
  color: #10ad7d !important;
  background: #b2ecdb !important;
  border-color: #b2ecdb !important;
  border-radius: 4px !important;
}

.ant-radio-button-wrapper {
  border-radius: 4px !important;
}

.ant-radio-button-wrapper:not(:first-of-type) {
  margin-left: 7px !important;
}

.desktop {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.ant-checkbox-group {
  display: inline-grid !important;
}

.ant-checkbox-wrapper {
  margin-left: 10px !important;
  text-align: center !important;
  margin-top: 20px !important;
}

.ant-collapse-borderless {
  background-color: #ffffff !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute !important;
  top: 1px !important;
  left: 0px !important;
  display: block !important;
  box-sizing: content-box !important;
  width: 1px !important;
  height: 85% !important;
  transition: background-color 0.3s !important;
}

.bg-secondary {
  footer {
    background-color: #fcfcfc !important;
  }
}

.popoverPrimary {
  .ant-popover-arrow-content {
    &::before {
      background-color: #6051e0;
    }
  }

  .ant-popover-inner {
    background-color: #6051e0;
    border-radius: 6px;
    width: 226px;

    .ant-popover-inner-content {
      p,
      li {
        color: #ffffff;
        margin: none;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
      }
    }
  }
}

.cdk-virtual-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  width: 5px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background: #6d54fc !important;
  border-radius: 8px !important;
}
